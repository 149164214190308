import BpkInfoBanner, {
  ALERT_TYPES as NEW_ALERT_TYPES,
} from '@skyscanner/backpack-web/bpk-component-info-banner';

import type { DisclaimerProps } from '@skyscanner-internal/falcon-shared-types/types/DisclaimerProps';

const createMarkup = (markup: string) => ({ __html: markup });
const FooterBlurb = ({ disclaimerCopy }: DisclaimerProps) => (
  <BpkInfoBanner
    message={<span dangerouslySetInnerHTML={createMarkup(disclaimerCopy)} />}
    type={NEW_ALERT_TYPES.INFO}
  />
);

export default FooterBlurb;
