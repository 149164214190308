import { redirect } from 'saddlebag-browser';

import BpkGraphicPromo, {
  TEXT_ALIGN,
} from '@skyscanner/backpack-web/bpk-component-graphic-promotion';

import withErrorBoundary from '../ErrorBoundary/WithErrorBoundary';
import FooterBlurb from '../common/FooterBlurb';
import { buildMBDParam, useMBDContext } from '../common/MBD/useMBDContext';

import type { MultiImageBannerProps } from '@skyscanner-internal/falcon-shared-types/types/MultiImageBannerProps';

import STYLES from './MultiImageBanner.module.scss';

const handleClick = (content: string, url: string) => {
  redirect(url);
};

const MultiImageBanner = (props: MultiImageBannerProps) => {
  const {
    backgroundImage: {
      backgroundImageDesktop,
      backgroundImageMobile,
      backgroundImageTablet,
    },
    buttonText,
    disclaimer,
    heading,
    subheading,
    url,
  } = props;

  const style = backgroundImageTablet
    ? {
        '--background-image-desktop': `url(${backgroundImageDesktop})`,
        '--background-image-mobile': `url(${backgroundImageMobile})`,
        '--background-image-tablet': `url(${backgroundImageTablet})`,
      }
    : {
        '--background-image-desktop': `url(${backgroundImageDesktop})`,
        '--background-image-mobile': `url(${backgroundImageMobile})`,
      };

  const { rawComponentName, vertical } = useMBDContext();

  return (
    <div
      data-tracking-element-id={rawComponentName}
      data-tracking-common-params={buildMBDParam(rawComponentName, vertical, {
        url,
      })}
    >
      <BpkGraphicPromo
        className={STYLES.MultiImageBanner}
        buttonText={buttonText}
        headline={heading}
        subheading={subheading}
        onClick={() => handleClick(buttonText, url)}
        style={style}
        textAlign={TEXT_ALIGN.start}
        invertVertically
      />
      {disclaimer && (
        <div className={STYLES.MultiImageBanner__disclaimer}>
          <FooterBlurb disclaimerCopy={disclaimer} />
        </div>
      )}
    </div>
  );
};

const MultiImageBannerBannerWithErrorBoundary = withErrorBoundary(
  MultiImageBanner,
  'multi-image-banner-component',
);

export default MultiImageBannerBannerWithErrorBoundary;
